import PropTypes from 'prop-types'
import React from 'react'

function LogoMobile({ className }) {
  return (
    <svg
      aria-hidden='true'
      focusable='false'
      data-icon='spring-initializr'
      role='img'
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      className={className}
      viewBox="0 0 280 80"
    >
      <g>
        <g>
          <g>
            <path class="steeltoe" d="m89.3,23.3c0.9,0.5 2.4,0.9 4.4,1.4s3.6,1 4.8,1.5s2.3,1.3 3.2,2.3s1.3,2.4 1.3,4.2c0,2.2 -0.9,3.9 -2.5,5.4c-1.7,1.4 -4.1,2.1 -7.2,2.1c-3.4,0 -6.4,-0.7 -9.2,-2.3c-0.6,-0.4 -0.9,-1 -0.9,-1.7c0,-0.5 0.2,-1 0.6,-1.4s0.8,-0.6 1.4,-0.6c0.3,0 0.6,0.1 0.9,0.2c1.1,0.5 2.2,0.8 3.2,1.1c1.1,0.3 2.3,0.4 3.6,0.4c3.7,0 5.6,-1.2 5.6,-3.6c0,-0.8 -0.4,-1.4 -1.3,-1.8c-0.9,-0.4 -2.3,-0.9 -4.2,-1.4c-2,-0.5 -3.6,-1 -4.9,-1.5s-2.4,-1.3 -3.3,-2.3s-1.4,-2.4 -1.4,-4.1c0,-2.3 0.9,-4.3 2.5,-5.8c1.7,-1.5 4.1,-2.2 7.3,-2.2c2.6,0 5,0.4 7.3,1.3c0.5,0.1 0.8,0.4 1.1,0.7c0.3,0.4 0.4,0.7 0.4,1.2s-0.2,1 -0.6,1.4s-0.9,0.6 -1.4,0.6c-0.2,0 -0.4,0 -0.7,-0.1c-1.9,-0.6 -3.9,-0.9 -5.8,-0.9c-1.8,0 -3.2,0.3 -4.3,1c-1,0.7 -1.5,1.5 -1.5,2.5c0.2,1.2 0.7,1.9 1.6,2.4z" />
            <path class="steeltoe" d="m122.8,36.1c0.4,0.4 0.6,0.8 0.6,1.3c0,0.8 -0.4,1.4 -1.2,1.8c-1.3,0.6 -2.7,0.9 -4.2,0.9c-4.9,0 -7.4,-2.2 -7.4,-6.7l0,-15.7l-3.6,0c-0.3,0 -0.5,-0.1 -0.7,-0.3c-0.2,-0.2 -0.3,-0.4 -0.3,-0.7c0,-0.3 0.1,-0.6 0.4,-0.9l6.9,-6.7c0.3,-0.3 0.6,-0.4 0.9,-0.4s0.5,0.1 0.7,0.3s0.3,0.4 0.3,0.7l0,4l5.7,0c0.6,0 1,0.2 1.4,0.6s0.6,0.8 0.6,1.4c0,0.5 -0.2,1 -0.6,1.4s-0.8,0.6 -1.4,0.6l-5.7,0l0,15.3c0,1.3 0.3,2.2 0.9,2.5c0.6,0.4 1.4,0.5 2.3,0.5c0.7,0 1.3,-0.1 2,-0.3c0.1,0 0.3,-0.1 0.4,-0.1s0.3,0 0.6,0c0.6,0 1,0.2 1.4,0.5z" />
            <path class="steeltoe" d="m149.4,34.6c0.4,0.4 0.6,0.8 0.6,1.4c0,0.7 -0.4,1.4 -1.3,1.9c-1.3,0.7 -2.6,1.3 -3.9,1.7c-1.3,0.4 -2.9,0.6 -5,0.6c-4.1,0 -7.3,-1.2 -9.6,-3.5s-3.5,-5.6 -3.5,-9.9c0,-2.4 0.4,-4.6 1.3,-6.6c0.9,-2 2.2,-3.7 4,-4.9c1.8,-1.2 4.1,-1.9 6.7,-1.9c2.5,0 4.7,0.6 6.5,1.8c1.8,1.2 3.2,2.7 4.1,4.7c0.9,1.9 1.4,4.1 1.4,6.3c0,0.6 -0.2,1.2 -0.6,1.6s-1,0.7 -1.7,0.7l-17.1,0c0.3,2.3 1.2,4.2 2.6,5.5c1.5,1.3 3.5,2 6.2,2c1.4,0 2.6,-0.1 3.7,-0.4c1.1,-0.3 2.1,-0.7 3.1,-1.2c0.3,-0.1 0.6,-0.2 0.9,-0.2c0.8,-0.2 1.2,0 1.6,0.4zm-13.9,-16.6c-1.1,0.5 -2,1.3 -2.8,2.5c-0.7,1.1 -1.2,2.5 -1.3,4.1l14.9,0c-0.1,-1.6 -0.5,-3 -1.3,-4.1c-0.7,-1.1 -1.7,-1.9 -2.8,-2.5c-1.1,-0.5 -2.2,-0.8 -3.4,-0.8c-1.1,0 -2.2,0.3 -3.3,0.8z" />
            <path class="steeltoe" d="m178.2,34.6c0.4,0.4 0.6,0.8 0.6,1.4c0,0.7 -0.4,1.4 -1.3,1.9c-1.3,0.7 -2.6,1.3 -3.9,1.7c-1.3,0.4 -2.9,0.6 -5,0.6c-4.1,0 -7.3,-1.2 -9.6,-3.5s-3.5,-5.6 -3.5,-9.9c0,-2.4 0.4,-4.6 1.3,-6.6c0.9,-2 2.2,-3.7 4,-4.9c1.8,-1.2 4.1,-1.9 6.7,-1.9c2.5,0 4.7,0.6 6.5,1.8c1.8,1.2 3.2,2.7 4.1,4.7c0.9,1.9 1.4,4.1 1.4,6.3c0,0.6 -0.2,1.2 -0.6,1.6s-1,0.7 -1.7,0.7l-17.1,0c0.3,2.3 1.2,4.2 2.6,5.5c1.5,1.3 3.5,2 6.2,2c1.4,0 2.6,-0.1 3.7,-0.4s2.1,-0.7 3.1,-1.2c0.3,-0.1 0.6,-0.2 0.9,-0.2c0.8,-0.2 1.2,0 1.6,0.4zm-13.9,-16.6c-1.1,0.5 -2,1.3 -2.8,2.5c-0.7,1.1 -1.2,2.5 -1.3,4.1l14.9,0c-0.1,-1.6 -0.5,-3 -1.3,-4.1c-0.7,-1.1 -1.7,-1.9 -2.8,-2.5c-1.1,-0.5 -2.2,-0.8 -3.4,-0.8c-1.1,0 -2.2,0.3 -3.3,0.8z" />
            <path class="steeltoe" d="m190.3,3.1c0.4,0.4 0.7,1 0.7,1.7l0,32.8c0,0.6 -0.2,1.2 -0.7,1.6c-0.4,0.4 -1,0.7 -1.6,0.7s-1.2,-0.2 -1.6,-0.7c-0.4,-0.4 -0.7,-1 -0.7,-1.6l0,-32.8c0,-0.6 0.2,-1.2 0.7,-1.6c0.4,-0.4 1,-0.7 1.6,-0.7c0.6,-0.1 1.1,0.2 1.6,0.6z" />
            <path class="steeltoe" d="m213.1,36.1c0.4,0.4 0.6,0.8 0.6,1.3c0,0.8 -0.4,1.4 -1.2,1.8c-1.3,0.6 -2.7,0.9 -4.2,0.9c-4.9,0 -7.4,-2.2 -7.4,-6.7l0,-15.7l-3.6,0c-0.3,0 -0.5,-0.1 -0.7,-0.3s-0.3,-0.4 -0.3,-0.7c0,-0.3 0.1,-0.6 0.4,-0.9l6.9,-6.7c0.3,-0.3 0.6,-0.4 0.9,-0.4s0.5,0.1 0.7,0.3s0.3,0.4 0.3,0.7l0,4l5.7,0c0.6,0 1,0.2 1.4,0.6s0.6,0.8 0.6,1.4c0,0.5 -0.2,1 -0.6,1.4s-0.8,0.6 -1.4,0.6l-5.7,0l0,15.3c0,1.3 0.3,2.2 0.9,2.5c0.6,0.4 1.4,0.5 2.3,0.5c0.7,0 1.3,-0.1 2,-0.3c0.1,0 0.3,-0.1 0.4,-0.1s0.3,0 0.6,0c0.7,0 1.1,0.2 1.4,0.5z" />
            <path class="steeltoe" d="m236.9,15c1.9,1.1 3.5,2.7 4.6,4.7s1.6,4.4 1.6,7s-0.5,5 -1.6,7s-2.6,3.6 -4.6,4.7c-1.9,1.1 -4.2,1.7 -6.8,1.7s-4.9,-0.6 -6.8,-1.7s-3.5,-2.7 -4.5,-4.7c-1.1,-2 -1.6,-4.4 -1.6,-7s0.5,-5 1.6,-7s2.6,-3.6 4.5,-4.7s4.2,-1.7 6.8,-1.7c2.5,0 4.8,0.6 6.8,1.7zm-2.9,19.9c1.3,-0.6 2.3,-1.6 3.1,-3s1.2,-3.1 1.2,-5.3c0,-2.1 -0.4,-3.8 -1.2,-5.2s-1.9,-2.4 -3.1,-3c-1.2,-0.6 -2.6,-0.9 -4,-0.9s-2.8,0.3 -4,0.9c-1.3,0.6 -2.3,1.6 -3.1,3s-1.2,3.1 -1.2,5.2s0.4,3.9 1.2,5.3s1.8,2.4 3.1,3s2.6,1 4,1c1.5,0 2.8,-0.3 4,-1z" />
            <path class="steeltoe" d="m270.5,34.6c0.4,0.4 0.6,0.8 0.6,1.4c0,0.7 -0.4,1.4 -1.3,1.9c-1.3,0.7 -2.6,1.3 -3.9,1.7s-2.9,0.6 -5,0.6c-4.1,0 -7.3,-1.2 -9.6,-3.5s-3.5,-5.6 -3.5,-9.9c0,-2.4 0.4,-4.6 1.3,-6.6s2.2,-3.7 4,-4.9c1.8,-1.2 4.1,-1.9 6.7,-1.9c2.5,0 4.7,0.6 6.5,1.8c1.8,1.2 3.2,2.7 4.1,4.7c0.9,1.9 1.4,4.1 1.4,6.3c0,0.6 -0.2,1.2 -0.6,1.6s-1,0.7 -1.7,0.7l-17.1,0c0.3,2.3 1.2,4.2 2.6,5.5c1.5,1.3 3.5,2 6.2,2c1.4,0 2.6,-0.1 3.7,-0.4s2.1,-0.7 3.1,-1.2c0.3,-0.1 0.6,-0.2 0.9,-0.2c0.8,-0.2 1.2,0 1.6,0.4zm-13.9,-16.6c-1.1,0.5 -2,1.3 -2.8,2.5c-0.7,1.1 -1.2,2.5 -1.3,4.1l14.9,0c-0.1,-1.6 -0.5,-3 -1.3,-4.1c-0.7,-1.1 -1.7,-1.9 -2.8,-2.5c-1.1,-0.5 -2.2,-0.8 -3.4,-0.8c-1.1,0 -2.2,0.3 -3.3,0.8z" />
            <g>
              <path class="steeltoe" d="m276.8,19.6c-1.7,0 -3.1,-1.4 -3.1,-3.1s1.4,-3.1 3.1,-3.1s3.1,1.4 3.1,3.1c0,1.7 -1.4,3.1 -3.1,3.1zm0,-5.8c-1.5,0 -2.6,1.2 -2.6,2.6c0,1.5 1.2,2.6 2.6,2.6c1.5,0 2.6,-1.2 2.6,-2.6s-1.2,-2.6 -2.6,-2.6zm0.8,4.5l-0.9,-1.4l-0.6,0l0,1.4l-0.5,0l0,-3.6l1.5,0c0.6,0 1.2,0.4 1.2,1.1c0,0.8 -0.7,1.1 -0.9,1.1l1,1.4l-0.8,0zm-0.6,-3.2l-1,0l0,1.3l1,0c0.3,0 0.6,-0.2 0.6,-0.6c0.1,-0.4 -0.3,-0.7 -0.6,-0.7z" />
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <polygon points="1.4,60.4 35.6,80.1 18.5,50.6" class="st0" />
                <polygon points="35.6,1.3 25.3,7.2 1.4,21 18.5,50.6 35.6,80.1 69.7,60.4" class="st1" />
              </g>
              <polyline points="61.3,46 1.4,21 1.4,21 35.6,1.3" class="st2" />
              <polygon points="35.6,1.3 1.4,21 35.6,40.7 35.6,40.7 69.7,21" class="st0" />
            </g>
          </g>
        </g>
        <g>
          <path class="initializr" d="m118.2,48.10588c0,-0.94594 0.85156,-1.80588 1.98696,-1.80588c1.13541,0 1.98696,0.85994 1.98696,1.80588c0,1.03193 -0.85156,1.80588 -1.98696,1.80588c-1.13541,0.08599 -1.98696,-0.77395 -1.98696,-1.80588zm0.5677,6.1916l2.93313,0l0,22.10056l-2.93313,0l0,-22.10056z" />
          <path class="initializr" d="m148.76136,62.20896l0,14.18908l-2.93313,0l0,-13.8451c0,-3.69776 -2.7439,-6.36359 -6.52859,-6.36359c-4.25778,0 -7.56938,2.66583 -7.56938,6.1056l0,14.10308l-2.93313,0l0,-22.10056l2.93313,0l0,3.61176c1.32464,-2.40784 4.44701,-4.12773 7.94785,-4.12773c5.39318,0 9.08325,3.52577 9.08325,8.42745z" />
          <path class="initializr" d="m155.28995,48.10588c0,-0.94594 0.85156,-1.80588 1.98696,-1.80588c1.13541,0 1.98696,0.85994 1.98696,1.80588c0,1.03193 -0.85156,1.80588 -1.98696,1.80588c-1.04079,0.08599 -1.98696,-0.77395 -1.98696,-1.80588zm0.5677,6.1916l2.93313,0l0,22.10056l-2.93313,0l0,-22.10056z" />
          <path class="initializr" d="m178.18732,75.5381c-1.23002,0.85994 -2.93313,1.37591 -4.54163,1.37591c-3.3116,0 -5.67703,-2.32185 -5.67703,-5.67563l0,-14.44706l-3.97392,0l0,-2.40784l3.97392,0l0,-6.1056l2.93313,0l0,6.1056l6.15012,0l0,2.40784l-6.15012,0l0,14.53305c0,1.89188 1.32464,3.18179 2.93313,3.18179c1.13541,0 2.1762,-0.34398 2.83852,-0.94594l1.51388,1.97787z" />
          <path class="initializr" d="m182.53971,48.10588c0,-0.94594 0.85156,-1.80588 1.98696,-1.80588c1.13541,0 1.98696,0.85994 1.98696,1.80588c0,1.03193 -0.85156,1.80588 -1.98696,1.80588c-1.13541,0.08599 -1.98696,-0.77395 -1.98696,-1.80588zm0.5677,6.1916l2.93313,0l0,22.10056l-2.93313,0l0,-22.10056z" />
          <path class="initializr" d="m212.53337,62.98291l0,13.50112l-2.93313,0l0,-3.52577c-1.79773,2.57983 -5.67703,4.04174 -8.89402,4.04174c-5.10933,0 -8.7994,-2.75182 -8.7994,-7.05154c0,-4.38571 4.16316,-7.39552 9.65096,-7.39552c2.64928,0 5.58242,0.51597 8.13708,1.28992l0,-0.85994c0,-3.26779 -1.60849,-6.79356 -7.00167,-6.79356c-2.46005,0 -5.01471,0.85994 -7.19091,1.97787l-1.13541,-2.14986c3.21699,-1.5479 6.24474,-2.14986 8.61017,-2.14986c6.15012,-0.08599 9.55634,3.78375 9.55634,9.11541zm-2.93313,6.53557l0,-3.52577c-2.27081,-0.60196 -5.01471,-1.11793 -7.664,-1.11793c-4.06854,0 -7.19091,2.06387 -7.19091,4.90168c0,2.92381 2.83852,4.81569 6.43397,4.81569c3.3116,0 7.47476,-1.63389 8.42093,-5.07367z" />
          <path class="initializr" d="m219.72428,46.55798l2.93313,0l0,29.92605l-2.93313,0l0,-29.92605z" />
          <path class="initializr" d="m229.09139,48.10588c0,-0.94594 0.85156,-1.80588 1.98696,-1.80588c1.13541,0 1.98696,0.85994 1.98696,1.80588c0,1.03193 -0.85156,1.80588 -1.98696,1.80588c-1.04079,0.08599 -1.98696,-0.77395 -1.98696,-1.80588zm0.5677,6.1916l2.93313,0l0,22.10056l-2.93313,0l0,-22.10056z" />
          <path class="initializr" d="m238.64773,74.33417l15.13876,-17.54286l-14.8549,0l0,-2.49384l18.73421,0l0,2.06387l-15.23337,17.54286l15.32799,0l0,2.49384l-19.11268,0l0,-2.06387z" />
          <path class="initializr" d="m276.4,53.78151l0,2.40784c-5.4878,0 -9.36711,3.26779 -9.36711,7.82549l0,12.38319l-2.93313,0l0,-22.10056l2.93313,0l0,4.5577c1.41926,-3.0098 4.9201,-5.07367 9.36711,-5.07367z" />
        </g>
      </g>
    </svg>
  )
}

LogoMobile.defaultProps = {
  className: '',
}

LogoMobile.propTypes = {
  className: PropTypes.string,
}

export default LogoMobile
