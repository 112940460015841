import PropTypes from 'prop-types'
import React from 'react'

function Logo({ className }) {
  return (
    <svg 
      aria-hidden='true'
      focusable='false'
      data-icon='spring-initializr'
      role='img'
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      className={className}
      viewBox="0 0 462.2 81.1"
    >
      <g>
        <g>
          <g>
            <path class="steeltoe" d="M89.3,36.3c0.9,0.5,2.4,0.9,4.4,1.4s3.6,1,4.8,1.5s2.3,1.3,3.2,2.3s1.3,2.4,1.3,4.2c0,2.2-0.9,3.9-2.5,5.4
				c-1.7,1.4-4.1,2.1-7.2,2.1c-3.4,0-6.4-0.7-9.2-2.3c-0.6-0.4-0.9-1-0.9-1.7c0-0.5,0.2-1,0.6-1.4s0.8-0.6,1.4-0.6
				c0.3,0,0.6,0.1,0.9,0.2c1.1,0.5,2.2,0.8,3.2,1.1c1.1,0.3,2.3,0.4,3.6,0.4c3.7,0,5.6-1.2,5.6-3.6c0-0.8-0.4-1.4-1.3-1.8
				c-0.9-0.4-2.3-0.9-4.2-1.4c-2-0.5-3.6-1-4.9-1.5s-2.4-1.3-3.3-2.3s-1.4-2.4-1.4-4.1c0-2.3,0.9-4.3,2.5-5.8
				c1.7-1.5,4.1-2.2,7.3-2.2c2.6,0,5,0.4,7.3,1.3c0.5,0.1,0.8,0.4,1.1,0.7c0.3,0.4,0.4,0.7,0.4,1.2s-0.2,1-0.6,1.4s-0.9,0.6-1.4,0.6
				c-0.2,0-0.4,0-0.7-0.1c-1.9-0.6-3.9-0.9-5.8-0.9c-1.8,0-3.2,0.3-4.3,1c-1,0.7-1.5,1.5-1.5,2.5C87.9,35.1,88.4,35.8,89.3,36.3z"/>
            <path class="steeltoe" d="M122.8,49.1c0.4,0.4,0.6,0.8,0.6,1.3c0,0.8-0.4,1.4-1.2,1.8c-1.3,0.6-2.7,0.9-4.2,0.9
				c-4.9,0-7.4-2.2-7.4-6.7V30.7H107c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.9l6.9-6.7
				c0.3-0.3,0.6-0.4,0.9-0.4s0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v4h5.7c0.6,0,1,0.2,1.4,0.6s0.6,0.8,0.6,1.4c0,0.5-0.2,1-0.6,1.4
				s-0.8,0.6-1.4,0.6h-5.7V46c0,1.3,0.3,2.2,0.9,2.5c0.6,0.4,1.4,0.5,2.3,0.5c0.7,0,1.3-0.1,2-0.3c0.1,0,0.3-0.1,0.4-0.1
				s0.3,0,0.6,0C122,48.6,122.4,48.8,122.8,49.1z"/>
            <path class="steeltoe" d="M149.4,47.6c0.4,0.4,0.6,0.8,0.6,1.4c0,0.7-0.4,1.4-1.3,1.9c-1.3,0.7-2.6,1.3-3.9,1.7
				c-1.3,0.4-2.9,0.6-5,0.6c-4.1,0-7.3-1.2-9.6-3.5s-3.5-5.6-3.5-9.9c0-2.4,0.4-4.6,1.3-6.6c0.9-2,2.2-3.7,4-4.9
				c1.8-1.2,4.1-1.9,6.7-1.9c2.5,0,4.7,0.6,6.5,1.8c1.8,1.2,3.2,2.7,4.1,4.7c0.9,1.9,1.4,4.1,1.4,6.3c0,0.6-0.2,1.2-0.6,1.6
				s-1,0.7-1.7,0.7h-17.1c0.3,2.3,1.2,4.2,2.6,5.5c1.5,1.3,3.5,2,6.2,2c1.4,0,2.6-0.1,3.7-0.4c1.1-0.3,2.1-0.7,3.1-1.2
				c0.3-0.1,0.6-0.2,0.9-0.2C148.6,47,149,47.2,149.4,47.6z M135.5,31c-1.1,0.5-2,1.3-2.8,2.5c-0.7,1.1-1.2,2.5-1.3,4.1h14.9
				c-0.1-1.6-0.5-3-1.3-4.1c-0.7-1.1-1.7-1.9-2.8-2.5c-1.1-0.5-2.2-0.8-3.4-0.8C137.7,30.2,136.6,30.5,135.5,31z"/>
            <path class="steeltoe" d="M178.2,47.6c0.4,0.4,0.6,0.8,0.6,1.4c0,0.7-0.4,1.4-1.3,1.9c-1.3,0.7-2.6,1.3-3.9,1.7
				c-1.3,0.4-2.9,0.6-5,0.6c-4.1,0-7.3-1.2-9.6-3.5s-3.5-5.6-3.5-9.9c0-2.4,0.4-4.6,1.3-6.6c0.9-2,2.2-3.7,4-4.9
				c1.8-1.2,4.1-1.9,6.7-1.9c2.5,0,4.7,0.6,6.5,1.8c1.8,1.2,3.2,2.7,4.1,4.7c0.9,1.9,1.4,4.1,1.4,6.3c0,0.6-0.2,1.2-0.6,1.6
				s-1,0.7-1.7,0.7h-17.1c0.3,2.3,1.2,4.2,2.6,5.5c1.5,1.3,3.5,2,6.2,2c1.4,0,2.6-0.1,3.7-0.4s2.1-0.7,3.1-1.2
				c0.3-0.1,0.6-0.2,0.9-0.2C177.4,47,177.8,47.2,178.2,47.6z M164.3,31c-1.1,0.5-2,1.3-2.8,2.5c-0.7,1.1-1.2,2.5-1.3,4.1h14.9
				c-0.1-1.6-0.5-3-1.3-4.1c-0.7-1.1-1.7-1.9-2.8-2.5c-1.1-0.5-2.2-0.8-3.4-0.8C166.5,30.2,165.4,30.5,164.3,31z"/>
            <path class="steeltoe" d="M190.3,16.1c0.4,0.4,0.7,1,0.7,1.7v32.8c0,0.6-0.2,1.2-0.7,1.6c-0.4,0.4-1,0.7-1.6,0.7s-1.2-0.2-1.6-0.7
				c-0.4-0.4-0.7-1-0.7-1.6V17.8c0-0.6,0.2-1.2,0.7-1.6c0.4-0.4,1-0.7,1.6-0.7C189.3,15.4,189.8,15.7,190.3,16.1z"/>
            <path class="steeltoe" d="M213.1,49.1c0.4,0.4,0.6,0.8,0.6,1.3c0,0.8-0.4,1.4-1.2,1.8c-1.3,0.6-2.7,0.9-4.2,0.9
				c-4.9,0-7.4-2.2-7.4-6.7V30.7h-3.6c-0.3,0-0.5-0.1-0.7-0.3s-0.3-0.4-0.3-0.7c0-0.3,0.1-0.6,0.4-0.9l6.9-6.7
				c0.3-0.3,0.6-0.4,0.9-0.4s0.5,0.1,0.7,0.3s0.3,0.4,0.3,0.7v4h5.7c0.6,0,1,0.2,1.4,0.6s0.6,0.8,0.6,1.4c0,0.5-0.2,1-0.6,1.4
				s-0.8,0.6-1.4,0.6h-5.7V46c0,1.3,0.3,2.2,0.9,2.5c0.6,0.4,1.4,0.5,2.3,0.5c0.7,0,1.3-0.1,2-0.3c0.1,0,0.3-0.1,0.4-0.1
				s0.3,0,0.6,0C212.4,48.6,212.8,48.8,213.1,49.1z"/>
            <path class="steeltoe" d="M236.9,28c1.9,1.1,3.5,2.7,4.6,4.7s1.6,4.4,1.6,7s-0.5,5-1.6,7s-2.6,3.6-4.6,4.7c-1.9,1.1-4.2,1.7-6.8,1.7
				s-4.9-0.6-6.8-1.7s-3.5-2.7-4.5-4.7c-1.1-2-1.6-4.4-1.6-7s0.5-5,1.6-7s2.6-3.6,4.5-4.7s4.2-1.7,6.8-1.7
				C232.6,26.3,234.9,26.9,236.9,28z M234,47.9c1.3-0.6,2.3-1.6,3.1-3s1.2-3.1,1.2-5.3c0-2.1-0.4-3.8-1.2-5.2s-1.9-2.4-3.1-3
				c-1.2-0.6-2.6-0.9-4-0.9s-2.8,0.3-4,0.9c-1.3,0.6-2.3,1.6-3.1,3s-1.2,3.1-1.2,5.2s0.4,3.9,1.2,5.3s1.8,2.4,3.1,3s2.6,1,4,1
				C231.5,48.9,232.8,48.6,234,47.9z"/>
            <path class="steeltoe" d="M270.5,47.6c0.4,0.4,0.6,0.8,0.6,1.4c0,0.7-0.4,1.4-1.3,1.9c-1.3,0.7-2.6,1.3-3.9,1.7s-2.9,0.6-5,0.6
				c-4.1,0-7.3-1.2-9.6-3.5s-3.5-5.6-3.5-9.9c0-2.4,0.4-4.6,1.3-6.6s2.2-3.7,4-4.9c1.8-1.2,4.1-1.9,6.7-1.9c2.5,0,4.7,0.6,6.5,1.8
				c1.8,1.2,3.2,2.7,4.1,4.7c0.9,1.9,1.4,4.1,1.4,6.3c0,0.6-0.2,1.2-0.6,1.6s-1,0.7-1.7,0.7h-17.1c0.3,2.3,1.2,4.2,2.6,5.5
				c1.5,1.3,3.5,2,6.2,2c1.4,0,2.6-0.1,3.7-0.4s2.1-0.7,3.1-1.2c0.3-0.1,0.6-0.2,0.9-0.2C269.7,47,270.1,47.2,270.5,47.6z M256.6,31
				c-1.1,0.5-2,1.3-2.8,2.5c-0.7,1.1-1.2,2.5-1.3,4.1h14.9c-0.1-1.6-0.5-3-1.3-4.1c-0.7-1.1-1.7-1.9-2.8-2.5
				c-1.1-0.5-2.2-0.8-3.4-0.8C258.8,30.2,257.7,30.5,256.6,31z"/>
            <g>
              <path class="steeltoe" d="M276.8,32.6c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1C279.9,31.2,278.5,32.6,276.8,32.6z
					 M276.8,26.8c-1.5,0-2.6,1.2-2.6,2.6c0,1.5,1.2,2.6,2.6,2.6c1.5,0,2.6-1.2,2.6-2.6S278.2,26.8,276.8,26.8z M277.6,31.3l-0.9-1.4
					h-0.6v1.4h-0.5v-3.6h1.5c0.6,0,1.2,0.4,1.2,1.1c0,0.8-0.7,1.1-0.9,1.1l1,1.4H277.6z M277,28.1h-1v1.3h1c0.3,0,0.6-0.2,0.6-0.6
					C277.7,28.4,277.3,28.1,277,28.1z"/>
            </g>
          </g>
        </g>
        <g>
          <g>
            <g>
              <g>
                <polygon class="st0" points="1.4,60.4 35.6,80.1 18.5,50.6" />
                <polygon class="st1" points="35.6,1.3 25.3,7.2 1.4,21 18.5,50.6 35.6,80.1 69.7,60.4" />
              </g>
              <polyline class="st2" points="61.3,46 1.4,21 1.4,21 35.6,1.3" />
              <polygon class="st0" points="35.6,1.3 1.4,21 35.6,40.7 35.6,40.7 69.7,21" />
            </g>
          </g>
        </g>
      </g>
      <g class="st4">
        <path class="initializr" d="M292.2,19.4c0-1.1,0.9-2.1,2.1-2.1c1.2,0,2.1,1,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1
		C293.1,21.6,292.2,20.6,292.2,19.4z M292.8,26.6h3.1v25.7h-3.1V26.6z"/>
        <path class="initializr" d="M324.5,35.8v16.5h-3.1V36.2c0-4.3-2.9-7.4-6.9-7.4c-4.5,0-8,3.1-8,7.1v16.4h-3.1V26.6h3.1v4.2
		c1.4-2.8,4.7-4.8,8.4-4.8C320.6,26,324.5,30.1,324.5,35.8z"/>
        <path class="initializr" d="M331.4,19.4c0-1.1,0.9-2.1,2.1-2.1c1.2,0,2.1,1,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1
		C332.4,21.6,331.4,20.6,331.4,19.4z M332,26.6h3.1v25.7H332V26.6z"/>
        <path class="initializr" d="M355.6,51.3c-1.3,1-3.1,1.6-4.8,1.6c-3.5,0-6-2.7-6-6.6V29.5h-4.2v-2.8h4.2v-7.1h3.1v7.1h6.5v2.8h-6.5v16.9
		c0,2.2,1.4,3.7,3.1,3.7c1.2,0,2.3-0.4,3-1.1L355.6,51.3z"/>
        <path class="initializr" d="M360.2,19.4c0-1.1,0.9-2.1,2.1-2.1c1.2,0,2.1,1,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1
		C361.1,21.6,360.2,20.6,360.2,19.4z M360.8,26.6h3.1v25.7h-3.1V26.6z"/>
        <path class="initializr" d="M391.9,36.7v15.7h-3.1v-4.1c-1.9,3-6,4.7-9.4,4.7c-5.4,0-9.3-3.2-9.3-8.2c0-5.1,4.4-8.6,10.2-8.6
		c2.8,0,5.9,0.6,8.6,1.5v-1c0-3.8-1.7-7.9-7.4-7.9c-2.6,0-5.3,1-7.6,2.3l-1.2-2.5c3.4-1.8,6.6-2.5,9.1-2.5
		C388.3,26,391.9,30.5,391.9,36.7z M388.8,44.3v-4.1c-2.4-0.7-5.3-1.3-8.1-1.3c-4.3,0-7.6,2.4-7.6,5.7c0,3.4,3,5.6,6.8,5.6
		C383.4,50.2,387.8,48.3,388.8,44.3z"/>
        <path class="initializr" d="M399.5,17.6h3.1v34.8h-3.1V17.6z" />
        <path class="initializr" d="M409.4,19.4c0-1.1,0.9-2.1,2.1-2.1c1.2,0,2.1,1,2.1,2.1c0,1.2-0.9,2.1-2.1,2.1
		C410.4,21.6,409.4,20.6,409.4,19.4z M410,26.6h3.1v25.7H410V26.6z"/>
        <path class="initializr" d="M419.5,49.9l16-20.4h-15.7v-2.9h19.8v2.4l-16.1,20.4h16.2v2.9h-20.2V49.9z" />
        <path class="initializr" d="M459.4,26v2.8c-5.8,0-9.9,3.8-9.9,9.1v14.4h-3.1V26.6h3.1v5.3C451,28.4,454.7,26,459.4,26z" />
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  className: '',
}

Logo.propTypes = {
  className: PropTypes.string,
}

export default Logo
